import { render, staticRenderFns } from "./background-images.vue?vue&type=template&id=336e6b63&scoped=true&"
import script from "./background-images.vue?vue&type=script&lang=js&"
export * from "./background-images.vue?vue&type=script&lang=js&"
import style0 from "./background-images.vue?vue&type=style&index=0&id=336e6b63&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "336e6b63",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/shopfront-stage/frontend/releases/20241019090600/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('336e6b63')) {
      api.createRecord('336e6b63', component.options)
    } else {
      api.reload('336e6b63', component.options)
    }
    module.hot.accept("./background-images.vue?vue&type=template&id=336e6b63&scoped=true&", function () {
      api.rerender('336e6b63', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/background-images.vue"
export default component.exports