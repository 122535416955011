import { render, staticRenderFns } from "./my-office.vue?vue&type=template&id=0c2a39a9&scoped=true&"
import script from "./my-office.vue?vue&type=script&lang=js&"
export * from "./my-office.vue?vue&type=script&lang=js&"
import style0 from "./my-office.vue?vue&type=style&index=0&id=0c2a39a9&scoped=true&lang=scss&"
import style1 from "./my-office.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c2a39a9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/shopfront-stage/frontend/releases/20241019090600/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0c2a39a9')) {
      api.createRecord('0c2a39a9', component.options)
    } else {
      api.reload('0c2a39a9', component.options)
    }
    module.hot.accept("./my-office.vue?vue&type=template&id=0c2a39a9&scoped=true&", function () {
      api.rerender('0c2a39a9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/my-office.vue"
export default component.exports